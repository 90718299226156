import { covidAlert } from '../shared/covid-alert'
import { serviceChangeFields } from '../shared/search'
import { assets } from './assets'
import { mapStyle } from './map'
import { survey } from './survey'

const directoryURL = window.REACT_APP_DIRECTORY_URL
// const assets = require('./assets' /** webpackChunkName: "assets" */)
var month = new Date().toLocaleString('en-US', {
  timeZone: 'Australia/Sydney',
  month: 'numeric',
})
const config = {
  id: 'default',

  options: {
    title: 'Access My Healthcare',
    categoryMode: 'multiple',
    combineMode: 'categories',
    defaultCategoryTitle: 'Healthcare Services',
    defaultCategorySuffix: 'Services',
    // maxVerboseCategories: 3,
  },

  analytics: {
    app: 'amh',
    plugins: [
      // {
      //   provider: 'google-analytics',
      //   // trackingId: 'UA-188798033-1', // AMS Test
      //   trackingId: 'UA-147876591-1',
      //   // customScriptSrc: 'https://www.google-analytics.com/analytics_debug.js',
      //   siteSpeedSampleRate: 100,
      // },
      {
        provider: 'cie-elastic',
        index: 'prod-ams-amh-analytics',
        // debug: true,
      },
    ],
    facebook: {
      tagId: '482689853334650',
    },
    googleSiteVerification: {
      tagId: '4ej5FtOzV-vTBi9njysRwwxeoE0kXoVwF5C2d5Z5_8w',
    },
    googleAnalytics: {
      containerId: 'GTM-P2ZGJHT',
    },
  },

  meta: {
    templates: {
      title: '',
      // titlePrefix: '{{ tenant.options.title }}',
      // titlePrefix: 'Big Red Button',
      description:
        'Connecting consumers, carers and clinicians to health services in the community. Powered by the Community Information Exchange, My Community Directory and My Community Diary.',
    },
  },

  routeOptions: {
    searchRouteHandler: {
      enabled: true,
      defaultRoute: {
        pathTypeName: 'search',
        path: '/search',
      },
    },
    pathOptions: {
      delimiter: '+',
    },
    parameterMaps: {
      state: {
        state: {
          source: 'state',
          map: ['longUrl'],
        },
      },
      council: {
        state: {
          source: 'council',
          map: ['stateUrl'],
        },
        council: {
          source: 'council',
          map: ['councilUrl'],
        },
      },
      locality: {
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      search: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
          multiple: ['urlFormatted', 'longUrl'],
        },
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      categories: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
        },
      },
      outletId: {
        outletId: {
          source: 'outletId',
          map: ['outletId'],
        },
        outletName: {
          source: 'outletName',
          map: ['outletName'],
        },
      },
    },
    generatedPaths: {
      home: {
        path: '/',
        canonical: '/',
      },
      search: {
        path: '/search',
        parameterMaps: ['search'],
      },
      about: {
        path: '/about',
        canonical: '/about',
      },
      support: {
        path: '/support',
        canonical: '/support',
      },
      regions: {
        path: '/regions/',
        canonical: '/regions',
      },
      categories: {
        path: '/categories/',
        canonical: '/categories',
      },
      browseState: {
        path: '/regions/:state',
        linkType: 'browse',
        parameterMaps: ['state'],
      },
      browseCouncil: {
        path: '/regions/:state/:council',
        linkType: 'browse',
        parameterMaps: ['council'],
      },
      browseLocality: {
        path: '/regions/:state/:council/:locality',
        linkType: 'browse',
        parameterMaps: ['locality'],
      },
      browseCategory: {
        path: '/categories/:categories',
        linkType: 'browse',
        parameterMaps: ['categories'],
      },
      searchCategories: {
        path: '/search/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocality: {
        path: '/search/:state/:council/:locality',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocalityCategories: {
        path: '/search/:state/:council/:locality/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      outletEvents: {
        path: '/outleteventcalendar/:outletId/:outletname',
        parameterMaps: ['outletId'],
      },
    },
  },

  routes: [
    {
      id: 'home',
      path: ['/', '/QLD', '/Queensland'],
      exact: true,
      container: 'ContentPage',
      component: 'Landing',
      props: {
        contentId: 'home',
      },
    },
    {
      id: 'search',
      path: [
        '/search',
        '/search/categories/:categories',
        '/search/:state/:council/:locality',
        '/search/:state/:council/:locality/categories/:categories',
      ],
      exact: true,
      component: 'Search',
      search: true,
      meta: {
        views: ['searchRoute'],
        templates: {
          title:
            '{{ search.categoriesSummary }} in {{ search.locationSummary }}',
          description:
            'Search for {{ search.categoriesSummary }} in {{ search.locationSummary }}. Powered by the Community Information Exchange, My Community Directory and My Community Diary.',
        },
      },
      pathConfig: {
        default: {
          redirectToStored: false,
        },
        root: {
          redirectToStored: true,
        },
      },
    },
    {
      id: 'browse',
      path: [
        '/regions/',
        '/regions/:state',
        '/regions/:state/:council',
        '/regions/:state/:council/:locality',
        '/categories/',
        '/categories/:categories',
      ],
      exact: true,
      component: 'Browse',
      container: 'ContentPage',
      meta: {
        views: ['regionsTitle', 'categoriesRoute'],
        templates: {
          '/regions/': {
            title: 'Browse States',
          },
          '/regions/:state': {
            title: 'Browse Councils in {{ route.regionsTitle.state }}',
          },
          '/regions/:state/:council': {
            title:
              'Browse Suburbs in {{ route.regionsTitle.council }}, {{ route.regionsTitle.state }}',
          },
          '/regions/:state/:council/:locality': {
            title:
              'Browse Healthcare Services in {{ route.regionsTitle.locality }}, {{ route.regionsTitle.state }}',
          },
          '/categories/': {
            title: 'Browse Healthcare Service Categories',
          },
          '/categories/:categories': {
            title:
              'Search for {{ route.categoriesRoute.source.categories.titleWithSuffix }} in your region',
            description:
              '{{ route.categoriesRoute.source.categories.description }}',
          },
        },
      },
      pathConfig: {
        default: {},
        regions: {
          query: 'states',
        },
        browseState: {
          query: 'councils',
        },
        browseCouncil: {
          query: 'localities',
        },
        browseLocality: {
          query: 'categories',
        },
        categories: {
          query: 'categoriesRoot',
        },
        browseCategory: {
          query: 'categoryInfo',
        },
      },
      options: {
        pathGenerator: 'search',
      },
    },
    {
      path: '/about',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'about',
      },
      meta: {
        templates: {
          title: 'About Us',
        },
      },
      pathConfig: {
        about: {},
      },
    },
    {
      path: '/support',
      exact: true,
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        templates: {
          title: 'Site Support',
        },
      },
      pathConfig: {
        support: {},
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: [
        '/search/:state/:council/:locality/category/:categories',
        '/category/:categories',
      ],
      component: 'SearchRedirect',
      props: {
        rewrites: [
          {
            from: '/search/:state/:council/:locality/category/:categories',
            to: '/search/:state/:council/:locality/categories/:categories',
          },
          {
            from: '/category/:categories',
            to: '/search/categories/:categories',
          },
        ],
      },
    },
    {
      path: '/outleteventcalendar/:outletId',
      container: 'ContentPage',
      component: 'HHSOutletEventCalendar',
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        templates: {
          title: '404 Not Found',
        },
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],

  categories: [
    {
      id: 'aboriginal-health-services',
      title: 'Aboriginal Health Services',
      shortUrl: 'Aboriginal',
      categories: [87],
      description:
        'Health services which consider the physical, cultural, social, and emotional wellbeing of Aboriginal and/or Torres Strait Islander people (Indigenous Australians).',
    },
    {
      id: 'ageing',
      title: 'Ageing',
      shortUrl: 'Ageing',
      categories: [144],
      description:
        'Aged care is the support provided to older people in their own home or in an aged care (nursing) home. It can include help with everyday living, health care, accommodation, and equipment such as walking frames or ramps.',
    },
    {
      id: 'alcohol-tobacco-and-other-drugs',
      title: 'Alcohol, Tobacco and Other Drugs',
      shortUrl: 'Substance',
      categories: [79],
      description:
        'A facility/service dedicated to the treatment of alcohol and drug dependence.',
    },
    {
      id: 'allied-health',
      title: 'Allied Health',
      shortUrl: 'Allied',
      categories: [137],
      description:
        'Allied Health Assistant services work under the direct supervision of health care professionals to provide therapeutic and program-related support including health promotion programs, health prevention programs, rehabilitation, cardiac, acute and primary care services to ensure improved access to services and streamline community and allied health services.',
    },
    {
      id: 'child-youth-and-family-health',
      title: 'Child Youth & Family Health',
      shortUrl: 'Child',
      categories: [145],
      description:
        'Child, youth, and family health services support families by offering services like: Counselling and parenting to develop positive parenting skills that strengthens family relationships, Hearing checks for children, Parenting classes, School-based youth health and Speech support',
    },
    {
      id: 'community-health-and-medical',
      title: 'Community Health & Medical',
      shortUrl: 'Community',
      categories: [78],
      description:
        'Community health services provide comprehensive primary healthcare services to encourage good health and prevent ill health. They can help people manage their health conditions to avoid hospitalisations. They can also help people to recover from hospital treatment in their own homes.',
    },
    {
      id: 'dental-and-oral-health',
      title: 'Dental & Oral Health',
      shortUrl: 'Dental',
      categories: [146],
      description:
        'Public dental services are delivered via a range of facilities including mobile dental clinics, school dental clinics and community dental clinics.',
    },
    {
      id: 'general-health-services',
      title: 'General Health Services',
      shortUrl: 'General',
      categories: [80, 140],
      description:
        'General health services refers to any and all health services from including community, primary, hospital and specialist health services.',
    },
    {
      id: 'general-practice',
      title: 'General Practice/Doctor',
      shortUrl: 'GP',
      categories: [141],
      description:
        'General Practice (or a doctor) plays a central role in the delivery of primary care services in Australia. They are often the first point of contact for people in the healthcare system.',
    },
    {
      id: 'health-screening-services',
      title: 'Health Screening Services',
      shortUrl: 'Screening',
      categories: [149],
      description:
        'Health screening services help reduce ill health and deaths from cancer. There are many types of screening services including: Breast, Cervical and Bowel',
    },
    {
      id: 'hearing-services',
      title: 'Hearing Services',
      shortUrl: 'Hearing',
      categories: [150],
      description:
        'Hearing services supports those who are at risk of losing their hearing or have lost their hearing.',
    },
    {
      id: 'mental-health-services',
      title: 'Mental Health Services',
      shortUrl: 'Mental Health',
      categories: [83],
      description:
        'Mental health services support individuals with a mental health condition. It may be provided by: allied health practitioners (e.g., occupational therapist), community mental health care services, emergency departments in hospitals, general practitioners (GPs), mental health nurse, psychiatrists, psychologists and residential mental health care',
    },
    {
      id: 'multi-cultural-and-refugee-health',
      title: 'Multi-cultural & Refugee Health',
      shortUrl: 'Multi-cultural',
      categories: [151],
      description:
        'Multicultural and refugee health services support people who may not have English as a first language to access health service and promote good health and wellbeing.',
    },
    {
      id: 'palliative-care',
      title: 'Palliative Care',
      shortUrl: 'Palliative',
      categories: [152],
      description:
        'Care in which the intent is primarily quality of life for a patient with an active, progressive disease with little or no prospect of cure.',
    },
    {
      id: 'pregnancy-maternity-and-newborn',
      title: 'Pregnancy, Maternity & Newborn',
      shortUrl: 'Maternity',
      categories: [154],
      description:
        'Pregnancy, maternity, and newborn services includes antenatal, during pregancy, and postnatal care for women and babies up to six weeks after birth.',
    },
    {
      id: 'psychiatric-services',
      title: 'Psychiatric Services',
      shortUrl: 'Psych',
      categories: [84],
      description: 'psychiatric services description',
    },
    {
      id: 'sexual-and-reproductive-health',
      title: 'Sexual and Reproductive Health',
      shortUrl: 'Reproductive',
      categories: [85],
      description:
        'Sexual and reproductive health provides education and support to individuals. Services may include: sexually transmitted services, contraception and health promotion and education like safe sex.',
    },
    {
      id: 'specialists',
      title: 'Specialists',
      shortUrl: 'Specialists',
      categories: [166],
      description:
        'Services provided by a specialist in hospital that require particular skills, training, or equipment.',
    },
  ],

  search: {
    router: {
      root: '/',
      parameterisedRoot: '/search',
      location: {
        prefix: null,
        format: 'state/council/locality',
      },
      category: {
        prefix: 'category',
        field: 'shortUrl',
        delimiter: '+',
      },
    },

    location: {
      showLocationDialog: true,
      initLocation: 'dialog',
      disableStateLabel: true,
      locationPropsLabel: true,
      filters: {
        states: ['QLD'],
      },
      defaultLocation: {
        type: 'locality',
        id: 1764,
        default: true,
        name: 'Brisbane',
        label: 'Brisbane 4000',
        councilId: 8,
        state: 'QLD',
        postcode: '4000',
        lat: -27.46977,
        lng: 153.02512,
        url: 'Brisbane',
        councilUrl: 'Brisbane',
        stateUrl: 'Queensland',
      },
    },

    queries: [
      {
        id: 'services',
        builder: 'directory',
        parameters: [
          { param: 'page' },
          { param: 'sort', sortField: 'Distance', sortDirection: 'ASC' },
          { param: 'query' },
          { param: 'categories' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'fixed', fields: { resolveLatLngToLocation: false } },
        ],
      },
      {
        id: 'events',
        builder: 'diary',
        parameters: [
          { param: 'page' },
          { param: 'query' },
          { param: 'categories' },
          { param: 'locationId' },
          { param: 'radius' },
          { param: 'dateRange' },
          { param: 'fixed', fields: { uniqueEvents: false } },
        ],
      },
    ],

    searches: {
      services: {
        id: 'services',
        type: 'directory',
        description: 'fixed services',
        pageSize: 100,
        showSiteSupport: true,
      },
      events: {
        id: 'events',
        type: 'diary',
        description: 'visiting services',
        pageSize: 30,
        showSiteSupport: true,
        options: {
          uniqueEvents: false,
        },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
  },

  sponsors: {
    itemSets: {
      default: ['ausstates', 'hhs', 'phn', 'lga', 'ciss', 'checkup'],
      hhs: ['hhs'],
      nohhs: ['ausstates', 'phn', 'lga', 'ciss', 'checkup'],
    },
    presets: {
      statehealth: {
        outletId: 140968,
        alt: 'Queensland Health',
        href: 'https://www.health.qld.gov.au',
        src: assets.sponsors.govQldBlack_5,
      },
      ciss: {
        outletId: 111626,
        alt: 'Community Information Support Services',
        href: 'https://www.communityinfo.org.au',
        src: assets.sponsors.cissSmall,
      },
      checkup: {
        outletId: 140098,
        alt: 'Checkup',
        href: 'https://www.checkup.org.au',
        src: assets.sponsors.checkupSmall,
      },
    },
    assetPrefix: 'sponsors',
    membershipOverrides: {
      2758: {
        outletId: 140968,
        preferredName: 'Queensland Health',
        website: 'https://www.health.qld.gov.au',
        logo: assets.sponsors.govQldBlack_5,
      },
    },
    assetMap: [
      { outletId: 140968, asset: 'govQldBlack_5' },

      { outletId: 140098, asset: 'checkupSmall' },
      { outletId: 111626, asset: 'cissSmall' },

      { outletId: 139041, asset: 'hhsQldCentralQueensland' },
      { outletId: 167381, asset: 'hhsQldCentralWest' },
      { outletId: 167522, asset: 'hhsQldSouthWest' },
      { outletId: 220958, asset: 'hhsQldDarlingDowns' },
      { outletId: 167483, asset: 'hhsQldMackay' },
      { outletId: 169122, asset: 'hhsQldNorthWest' },
      { outletId: 168664, asset: 'hhsQldTownsville' },
      { outletId: 114921, asset: 'hhsQldWideBay' },

      { outletId: 179194, asset: 'phnNorthernQueensland' },
      { outletId: 167327, asset: 'phnWesternQueensland' },
      // { geographyId: 2758, asset: }
    ],
  },

  ui: {
    appBar: {
      elevation: 0,
      // position: 'static',
      groups: [
        {
          align: 'left',
          items: [
            {
              component: 'MenuButton',
              props: {
                sx: { px: 2 },
              },
            },
            {
              component: 'AppBarTitle',
              props: {
                logo: month === '12' ? 'santa' : 'textLogo',
                disableTextTitle: true,
                to: '/',
              },
            },
          ],
        },
        {
          align: 'fill',
          items: [
            {
              component: 'Sponsors',
              props: {
                label: 'In collaboration with',
                itemSet: { ph: 'hhs' },
                assetPrefix: {
                  xs: 'sponsors',
                  lg: ['sponsors.large', 'sponsors'],
                },
              },
            },
          ],
        },
        {
          align: 'right',
          items: [
            {
              component: 'AppBarButton',
              // media: [{ query: 'up', breakpoints: ['tb'], prop: 'render' }],
              props: {
                icon: 'fas fa-sign-in',
                label: 'Sign In',
                href: `${directoryURL}/Account/Login?loginfrom=accessmyhealthcare.com.au`,
                target: '_blank',
                variant: 'text',
                color: 'primary',
                disableElevation: true,
              },
            },
          ],
        },
      ],
    },

    footer: {
      sections: [
        {
          theme: 'variants.footerSponsors',
          blocks: [
            {
              component: 'Sponsors',
              props: {
                itemSet: {
                  xs: 'default',
                  ph: 'nohhs',
                },
              },
            },
          ],
        },
        {
          theme: 'variants.footer',
          blocks: [
            {
              component: 'CieBlock',
              gridProps: { xs: 12, md: 4 },
            },
            {
              title: {
                label: 'Menu',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'navigation',
                hide: 'footer',
              },
            },
            {
              title: {
                label: 'Contact',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'contact',
                hide: 'footer',
              },
            },
          ],
        },
        {
          theme: 'variants.footerAtsi',
          blocks: [
            {
              component: 'Acknowledgement',
            },
          ],
        },
      ],
    },

    menus: {
      navigation: {
        items: [
          {
            id: 'home',
            label: 'Home',
            icon: 'fas fa-home-alt',
            route: '/',
          },
          {
            id: 'search',
            label: 'Search',
            icon: 'fas fa-search',
            route: '/search',
          },
          {
            id: 'regions',
            label: 'Browse Regions',
            icon: 'fas fa-globe-stand',
            route: '/regions',
          },
          {
            id: 'categories',
            label: 'Browse Categories',
            icon: 'far fa-stream',
            route: '/categories',
          },
          {
            id: 'about',
            label: 'About Us',
            icon: 'fas fa-question-circle',
            route: '/about',
          },
          {
            id: 'sitemap',
            label: 'Site Map',
            icon: 'fas fa-solid fa-map',
            href: '/sitemap.xml',
          },
          {
            type: 'divider',
            hide: ['footer'],
          },
          {
            id: 'support',
            label: 'Site Support',
            icon: 'fas fa-user-headset',
            route: '/support',
          },
          {
            id: 'signIn',
            label: 'Sign In',
            icon: 'fas fa-sign-in',
            href: 'https://www.mycommunitydirectory.com.au/Account/Login?loginfrom=accessmyhealthcare.com.au',
            hide: ['footer'],
          },
          {
            id: 'reset',
            label: 'Clear Settings & Restart',
            icon: 'fas fa-undo',
            function: 'reset',
            hide: ['footer'],
          },
        ],
      },
      contact: {
        items: [
          // {
          //   id: 'web',
          //   label: 'mycommunitydirectory.com.au',
          //   icon: 'far fa-globe',
          //   href: 'mycommunitydirectory.com.au',
          //   // footer: true,
          // },
          {
            id: 'email',
            label: 'support@accessmyhealthcare.com.au',
            icon: 'fas fa-envelope',
            href: 'mailto:support@20l7a3h3mxcmdxllmpw6ec8to1mt7fom25avf0zw2hs9fdby6e.2w-d2zteac.ap28.case.salesforce.com',
            // footer: true,
          },
          {
            id: 'phone',
            label: '1300 762 515',
            icon: 'fas fa-phone',
            href: 'tel:1300762515',
            // footer: 'true',
          },
          {
            id: 'address',
            label: 'PO Box 222\nFortitude Valley, QLD, 4006',
            icon: 'fas fa-map-marker-alt',
            // href: 'https://goo.gl/maps/YAuWmZ1FC488TMDz8',
            // footer: true,
          },
        ],
      },
    },

    search: {
      tabs: [
        {
          id: 'services',
          title: 'Fixed',
          icon: 'fas fa-building',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'services',
          },
        },
        {
          id: 'events',
          title: 'Visiting',
          icon: 'fas fa-shuttle-van',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'events',
          },
        },
        {
          id: 'calendar',
          title: 'Calendar',
          icon: 'fas fa-calendar',
          component: 'Calendar',
          map: false,
          options: {
            search: 'favourites',
          },
        },
        {
          id: 'favourites',
          title: 'Favourites',
          icon: 'fas fa-star',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'favourites',
          },
        },
      ],

      desktop: {
        homeButton: {
          show: true,
          path: '/',
        },
        paramsDrawer: {
          breakpoint: 'lg',
        },
        disableParams: true,

        mapFooter: {
          sections: [
            // {
            //   component: 'HHSOutlets',
            // },
            {
              component: 'Sponsors',
              props: {
                itemSet: {
                  // xs: 'default',
                  // ph: 'nohhs',
                  xs: 'nohhs',
                },
              },
            },
            { component: 'Acknowledgement' },
          ],
        },
      },

      map: {
        locationZoom: 15,
        selectionZoom: 17,
        style: mapStyle,
      },

      parameters: {
        query: {
          id: 'query',
          type: 'query',
          label: 'Search',
          icon: 'fa-solid fa-magnifying-glass',
        },
        location: {
          id: 'location',
          type: 'location',
          label: 'Location',
          icon: 'fas fa-map-marker-question',
        },
        outlets: {
          id: 'outlets',
          type: 'outlets',
          // label: 'Locations',
        },
        radius: {
          id: 'radius',
          type: 'radius',
          label: 'Radius',
          icon: 'far fa-running',
        },
        dateRange: {
          id: 'dateRange',
          type: 'dateRange',
          label: 'Appointment Dates',
          icon: 'far fa-clock',
          question: 'When?',
        },
        categories: {
          id: 'categories',
          type: 'categoryButtons',
          label: 'Categories',
          icon: 'far fa-stream',
        },
      },

      parameterGroups: {
        desktop: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'outlets' },
            { param: 'radius' },
            { param: 'dateRange' },
            { param: 'categories' },
          ],
        },
        mobileSearch: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'dateRange' },
          ],
        },
        mobileCategories: {
          controls: [{ param: 'categories' }],
        },
      },

      resultsList: {
        default: {
          sections: [
            {
              type: 'alertBlock',
              items: [{ component: 'CovidAlert' }],
            },
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'statusHeaderBlock',
              items: [{ component: 'StatusHeader' }],
            },
            {
              type: 'statusSponsorBlock',
              items: [
                {
                  component: 'Sponsors',
                  theme: 'variants.searchResultsHHS',
                  props: {
                    label: 'In collaboration with',
                    itemSet: {
                      xs: 'hhs',
                      // ph: 'nohhs',
                    },
                    assetPrefix: {
                      xs: 'sponsors',
                      vs: ['sponsors.large', 'sponsors'],
                    },
                  },
                },
              ],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'Status' }, { component: 'SiteSupport' }],
            },
            {
              type: 'footerBlock',
              items: [
                { component: 'Divider' },
                {
                  component: 'Sponsors',
                  theme: 'variants.searchResultsFooter',
                  props: {
                    itemSet: {
                      xs: 'nohhs',
                      // xs: 'default',
                      // ph: 'nohhs',
                    },
                  },
                },
                { component: 'Acknowledgement' },
              ],
            },
          ],
        },
        favourites: {
          sections: [
            // {
            //   type: 'alertBlock',
            //   items: [
            //     { component: 'CovidAlert' },
            //   ],
            // },
            // {
            //   type: 'initBlock',
            //   items: [
            //     { component: 'Welcome' },
            //   ],
            // },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [
                { component: 'FavouritesStatus' },
                { component: 'SiteSupport' },
              ],
            },
            {
              type: 'footerBlock',
              items: [
                { component: 'Divider' },
                {
                  component: 'Sponsors',
                  props: {
                    itemSet: {
                      xs: 'default',
                      ph: 'nohhs',
                    },
                  },
                },
                { component: 'Acknowledgement' },
              ],
            },
          ],
        },
      },

      resultItem: {
        service: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'directory',
                showDistance: true,
              },
            },
            {
              component: 'Title',
              options: {
                showOrganisationName: true,
              },
            },
            {
              component: 'Details',
              fields: [
                { key: 'operatingDays', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
              hideVariant: 'map',
            },
            {
              component: 'HealthEngine',
              hideVariant: 'map',
            },
          ],
        },
        event: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'diary',
                showDistance: true,
              },
            },
            {
              component: 'Title',
            },
            {
              component: 'Details',
              fields: [
                { key: 'dateString', icon: 'far fa-calendar' },
                { key: 'time', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
            },
          ],
        },
      },
    },

    print: { isShowPrintButton: true },
  },

  assets,
  survey,
  covidAlert,
}

export default config
